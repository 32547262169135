<template>
  <div>
    <b-row>
      <b-col cols="4"></b-col>
      <b-col cols="4">
        <b-input-group>
          <b-form-input
            placeholder="Songs or Playlists"
            v-model="keyword"
            @keyup.enter="search">
          </b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="search">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4"></b-col>
    </b-row>

    <div v-if="showResults">
      <div class="result-container">
        <!-- Selected Playlist Song List -->
        <SearchSongList
          :plistId.sync="selectedPlaylist.music_playlist_id"
          :headingTitle="`${selectedPlaylist.name} Playlist`"
          listType="plistSongs"
          endpoint="api/playlist/songs"
          v-show="selectedPlaylist.music_playlist_id"
          @song-added="refreshParentSongList"
        />

        <!-- Search Result - Songs -->
        <SearchSongList
          listType="songsList"
          headingTitle="Songs"
          endpoint="api/search/song"
          :filter.sync="keyword"
          v-show="selectedPlaylist.music_playlist_id === null"
          @song-added="refreshParentSongList"
        />

        <!-- Search Result - Playlists -->
        <h4>Playlists</h4>
        <div class="playlists">
          <p
            class="text-center no-result"
            v-if="emptyPlaylists">
            No Playlist Available
          </p>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                lg="3"
                xl="3"
                v-for="(playlist, index) in playlists.data"
                :key="index">
                <SearchPlaylistCard
                  :data="playlist"
                  :selectedId="selectedPlaylist.music_playlist_id"
                  @selected-playlist="selectPlaylist(playlist)"
                />
              </b-col>
            </b-row>

            <!-- Playlist Pagination -->
            <div v-if="playlists.data != '' &&
              playlists.total > playlists.perPage">
              <b-pagination
                align="center"
                size="md"
                v-model="plistCurrPage"
                :per-page="playlists.perPage"
                :total-rows="playlists.total"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <RecentSongList :data="recentSongs" v-else />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    mixins : [
      FilterMixin,
    ],
    data() {
      return {
        keyword          : null,
        showResults      : false,
        playlists        : {},
        selectedPlaylist : {
          'music_playlist_id' : null,
        },
        plistCurrPage  : 1,
        plistPerPage   : 8,
        emptyPlaylists : true,
      }
    },
    computed : {
      ...mapGetters({
        recentSongs : 'playlist/recentSongs',
      }),
    },
    components : {
      'SearchSongList'     : () => import('./SearchSongList'),
      'SearchPlaylistCard' : () => import('./SearchPlaylistCard'),
      'RecentSongList'     : () => import('./RecentSongList'),
    },
    watch : {
      keyword(val) {
        if (!val) {
          this.playlists = {};
          this.selectedPlaylist = {
            'music_playlist_id' : null,
          };
          this.showResults = false;
        }
      },
      plistCurrPage(val) {
        this.searchPlaylist(val);
      },
    },
    methods : {

      /**
       * Search Song or Playlist
       */
      search() {
        if (this.keyword) {
          this.showResults = true;
          this.searchPlaylist(this.plistCurrPage);
        }
      },

      /**
       * Search Song
       * @param currentPage
       */
      searchPlaylist(currentPage = this.playlists.currentPage) {
        this.$http.get('api/search/playlist', {
          params : {
            filter  : this.keyword,
            page    : currentPage,
            perPage : this.plistPerPage,
          },
        }).then(response => {
          this.emptyPlaylists = false;
          this.playlists = response.data.data;
        }).catch(() => {
          this.playlists = {};
          this.emptyPlaylists = true;
        })
      },

      /**
       * Set Selected Playlist
       * @param playlist
       */
      selectPlaylist(playlist) {
        if (this.selectedPlaylist.music_playlist_id) {
          if (this.selectedPlaylist.music_playlist_id ===
            playlist.music_playlist_id) {
            this.selectedPlaylist = {
              'music_playlist_id' : null,
            };
          } else
            this.selectedPlaylist = playlist;
        } else
          this.selectedPlaylist = playlist;
      },

      /**
       * Refresh Parent Song List
       */
      refreshParentSongList() {
        this.$emit('song-added');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";
  .result-container {
    min-height: 30vh;
  }

  .title {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .no-result {
    padding: 0.5rem 0;
    font-size: 1em;
  }
</style>